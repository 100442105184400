:root {
  --s-textcolor: #000000;
  --s-textcolor-02: #2F1657;
  --s-textcolor-03: #D6294A;
  --s-sitecolor: #2F1657;
  --s-sitecolor-02: #DFFE52;
  --s-sitecolor-03: #D6294A;
  --s-linkcolor: #000000;
  --s-linkcolor-hov: #2F1657;
  --s-btn-bgcolor: transparent;
  --s-btn-color: #D6294A;
  --s-btn-border: #D6294A;
  --s-btn-border-hov: #D6294A;
  --s-btn-bgcolor-hov: #D6294A;
  --s-btn-color-hov: #FFFFFF;
  --s-btn-secondary-color: #D6294A;
  --s-pdf-color: #000000;
  --s-pdf-bgcolor-hov: #000000;
  --s-pdf-color-hov: #FFFFFF;
  --s-pdf-border: #000000;
  --s-slider-control-color: #2F1657;
}
:root {
  --deskWidth: 1280px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .button:hover:before,
  .button:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .button:hover:after,
  .button:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.button-pdf {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button-pdf:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translateY(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pdf-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button-pdf:hover,
  .button-pdf:focus {
    color: var(--s-pdf-color-hov);
    background-size: 100% 100%;
  }
  .button-pdf:hover:before,
  .button-pdf:focus:before {
    background-color: var(--s-pdf-color-hov);
  }
}
/*
 *	WebFont Package Licensed to Tollkirsch AG for Mark it GmbH
 */
/* source-serif-4-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Serif 4';
  font-style: italic;
  font-weight: 400;
  src: url('/extras/fonts/source-serif-4-v8-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-serif-4-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Serif 4';
  font-style: italic;
  font-weight: 700;
  src: url('/extras/fonts/source-serif-4-v8-latin-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/montserrat-v29-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/montserrat-v29-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/montserrat-v29-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/montserrat-v29-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/extras/fonts/montserrat-v29-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: var(--s-sitecolor);
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.44444444;
}
.unit caption {
  display: none;
}
.flag {
  background: var(--s-sitecolor);
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.unit__background img,
.pict img {
  width: 100%;
}
.unit__background img:not(.svg),
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  margin-top: calc(var(--spacePart) * 3);
}
div.load + .part--link,
div.load + .part.load {
  margin-top: var(--spacePart);
}
div.load.part--styleOne {
  background: none !important;
}
div.load.part--styleOne a.load {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
div.load.part--styleOne a.load:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translateY(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pdf-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
@media (hover: hover) and (pointer: fine) {
  div.load.part--styleOne a.load:hover,
  div.load.part--styleOne a.load:focus {
    color: var(--s-pdf-color-hov);
    background-size: 100% 100%;
  }
  div.load.part--styleOne a.load:hover:before,
  div.load.part--styleOne a.load:focus:before {
    background-color: var(--s-pdf-color-hov);
  }
}
div.load.part--styleTwo {
  background: none !important;
}
div.load.part--styleTwo a.load {
  width: 100%;
  display: inline-block;
  color: var(--s-textcolor);
  cursor: pointer;
  appearance: none;
  border-bottom: 1px solid var(--s-textcolor);
  padding-right: 35px;
  padding-bottom: calc(var(--spacePart) * 1);
  box-sizing: border-box;
  position: relative;
}
div.load.part--styleTwo a.load:before {
  content: '';
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  transform: translateY(-50%);
  width: 16px;
  height: 21px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pdf-black.svg);
  background-color: var(--s-textcolor);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
  color: var(--s-textcolor);
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
#expo:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
  overflow: hidden;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: top;
}
#expo div.link {
  display: flex;
  flex-direction: row;
  gap: 24px;
  float: left;
  position: absolute;
  right: clamp(var(--deskSpace), calc((100vw - 1280px) / 2), calc((100vw - 1280px) / 2));
  bottom: 50px;
}
@media (max-width: 1023px) {
  #expo div.link {
    bottom: 30px;
  }
}
#expo a.link {
  position: relative;
  z-index: 2;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  height: 20px;
  padding-left: 24px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#expo a.link.prev {
  background-image: url(/images/icon-arrow-short-left-white.svg);
}
#expo a.link.next {
  background-image: url(/images/icon-arrow-short-right-white.svg);
}
table.link,
table.link tbody {
  float: left;
  display: none;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: none;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: var(--s-sitecolor);
}
.cb-index-some {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--s-sitecolor);
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: var(--s-sitecolor);
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
  line-height: 1.42857143;
}
#disp .foot input:before,
#disp .foot a:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
#disp .foot input:after,
#disp .foot a:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  #disp .foot input:hover:before,
  #disp .foot a:hover:before,
  #disp .foot input:focus:before,
  #disp .foot a:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  #disp .foot input:hover:after,
  #disp .foot a:hover:after,
  #disp .foot input:focus:after,
  #disp .foot a:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
  font-style: italic;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 17px;
    padding-bottom: 17px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  min-height: 60px;
  padding: 5px 10px;
  -webkit-appearance: none;
  border: 1px solid #000000;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
  accent-color: var(--s-sitecolor);
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 17px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  background: #ffffff;
  height: 60px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.unit.form .submit:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.unit.form .submit:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .unit.form .submit:hover,
  .unit.form .submit:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .unit.form .submit:hover:before,
  .unit.form .submit:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .unit.form .submit:hover:after,
  .unit.form .submit:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.two-step-verification-container a:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.two-step-verification-container a:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .two-step-verification-container a:hover:before,
  .two-step-verification-container a:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .two-step-verification-container a:hover:after,
  .two-step-verification-container a:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #DFFE52;
  border-bottom: 1px solid #DFFE52;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 12px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 12px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
main {
  float: left;
  width: 100%;
  flex: 1 0;
}
.home {
  float: left;
  height: 26px;
  position: relative;
}
.logo {
  width: auto;
  height: 100%;
}
.logo--pos {
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.logo--neg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.cb-toggle-target-active .logo--pos {
  opacity: 0;
}
.cb-toggle-target-active .logo--neg {
  opacity: 1;
}
#head {
  float: left;
  width: 100%;
}
body.layout1 #head,
body.layout2 #head {
  margin-top: calc(var(--spaceTotal) * 2);
}
body.layout3 #head {
  margin-top: calc(var(--spaceTotal) * 4);
}
@media (max-width: 767px) {
  body.layout3 #head {
    margin-top: calc(var(--spaceTotal) * 2);
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  color: var(--s-textcolor);
  accent-color: var(--s-sitecolor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 16px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  z-index: 2000;
  background: #2F1657;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.cb-toggle-target-active .section--header {
  background: transparent;
}
.container--head {
  float: left;
  width: 100%;
  display: flex;
  margin: 20px 0;
  box-sizing: border-box;
}
.section--color-02 {
  background-color: #DFFE52;
}
.section--color-03 {
  background-color: #D6294A;
  --s-textcolor: #FFFFFF;
  --s-textcolor-02: #DFFE52;
  --s-textcolor-03: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hov: #2F1657;
  --s-btn-border: #FFFFFF;
  --s-btn-color: #FFFFFF;
  --s-btn-color-hov: #FFFFFF;
  --s-btn-secondary-color: #FFFFFF;
  --s-pdf-color: #FFFFFF;
  --s-pdf-bgcolor-hov: #FFFFFF;
  --s-pdf-color-hov: #000000;
  --s-pdf-border: #FFFFFF;
  --s-slider-controlcolor: #FFFFFF;
}
.section--lightgray {
  background-color: #EBEBEB;
}
.section--multimood {
  --s-textcolor: #FFFFFF;
  --s-textcolor-02: #FFFFFF;
  --s-textcolor-03: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hov: #2F1657;
}
body.layout1 .section--multimood {
  height: 60.05208333vw;
  min-height: 575px;
  max-height: 100svh;
}
@media (max-width: 1023px) {
  body.layout1 .section--multimood {
    height: 107.421875vw;
  }
}
@media (max-width: 767px) {
  body.layout1 .section--multimood {
    height: 128.20512821vw;
  }
}
.container--mood {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  isolation: isolate;
  padding-top: 80px;
  padding-bottom: 80px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .container--mood {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .container--mood {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}
body.layout1 .container--mood {
  height: 100%;
}
body.layout2 .container--mood {
  height: 650px;
  justify-content: center;
}
@media (max-width: 1023px) {
  body.layout2 .container--mood {
    height: 480px;
  }
}
@media (max-width: 767px) {
  body.layout2 .container--mood {
    height: 320px;
  }
}
.container--mood .mood {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #2F1657;
}
.container--mood .content {
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  box-sizing: border-box;
}
body.layout2 .container--mood .content {
  padding-left: var(--deskSpace);
  padding-right: var(--deskSpace);
}
.container--mood .content #head,
.container--mood .content .cbdModule--moodTitle {
  max-width: 950px;
}
.section--footer {
  flex-shrink: 0;
}
.footarea {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
@media (max-width: 1023px) {
  .footarea {
    margin-top: calc(var(--spaceTotal) * 2);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.footpart {
  float: left;
  width: 100%;
  margin-top: calc(var(--spacePart) * 2);
  margin-bottom: calc(var(--spacePart) * 2);
}
.footpart--five {
  margin-top: calc(var(--spaceTotal) + (var(--spacePart) * 4));
}
.footpart a {
  color: var(--s-linkcolor);
}
@media (hover: hover) and (pointer: fine) {
  .footpart a:hover,
  .footpart a:focus {
    color: var(--s-linkcolor-hov);
  }
}
.vcard--foot {
  float: left;
  width: 100%;
  color: var(--s-textcolor);
}
.foottitle {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--s-textcolor);
}
.footOpenings {
  display: block;
  font-size: 17px;
  line-height: 1.29411765;
  color: var(--s-textcolor);
}
.unit--footLinks {
  --spaceUnit: 0;
  --spacePart: 0;
}
#edit .unit--footLinks {
  min-height: 20px;
  outline-color: #2F1657;
}
.unit--footLegal {
  --spaceUnit: 0;
  --spacePart: 0;
  --s-linkcolor: #D6D6D6;
  font-size: 14px;
  line-height: 1.42857143;
}
#edit .unit--footLegal {
  min-height: 20px;
  outline-color: #fff;
}
.unit--footLegal .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.unit--footLegal .part {
  width: auto;
}
.unit--footLegal .part:not(:last-child):after {
  content: '|';
  margin: 0 4px;
  color: #D6D6D6;
}
.footlogo {
  width: 210px;
}
a {
  color: var(--s-linkcolor);
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
h1,
h2 {
  font-size: 15px;
  line-height: 1.33333333;
  color: var(--s-textcolor-03);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}
@media (max-width: 767px) {
  h1,
  h2 {
    font-size: 14px;
    line-height: 1.28571429;
  }
}
h3 {
  font-size: 34px;
  line-height: 1.11764706;
  color: var(--s-textcolor-02);
  font-weight: 800;
}
@media (max-width: 1023px) {
  h3 {
    font-size: 30px;
    line-height: 1;
  }
}
h4 {
  font-size: 26px;
  line-height: 1.15384615;
  color: var(--s-textcolor-02);
  font-weight: bold;
}
p.loud {
  font-family: 'Source Serif 4', serif;
  font-size: 25px;
  line-height: 1.4;
  letter-spacing: 0.005em;
  color: var(--s-textcolor-02);
}
@media (max-width: 1023px) {
  p.loud {
    font-size: 22px;
    line-height: 1.45454545;
  }
}
p.loud > .open {
  color: var(--s-sitecolor);
}
@media (hover: hover) and (pointer: fine) {
  p.loud > .open:hover,
  p.loud > .open:focus {
    text-decoration: underline;
    color: var(--s-sitecolor);
  }
}
p.norm {
  color: var(--s-textcolor);
}
p.norm > .open {
  color: var(--s-sitecolor);
}
@media (hover: hover) and (pointer: fine) {
  p.norm > .open:hover,
  p.norm > .open:focus {
    text-decoration: underline;
    color: var(--s-sitecolor);
  }
}
.cbdModule--moodTitle {
  margin-top: 10px;
}
.moodtitle {
  font-size: 70px;
  line-height: 1.14285714;
  font-weight: 800;
  color: var(--s-textcolor);
  text-transform: none;
  letter-spacing: 0;
}
@media (max-width: 1023px) {
  .moodtitle {
    font-size: 36px;
    line-height: 1.27777778;
  }
}
.vcard {
  font-weight: 300;
}
.vcardTitle {
  font-weight: bold;
}
.openingHours {
  float: left;
  width: 290px;
}
.unit--openingHours {
  --spacePart: 0;
  margin: 0;
}
#edit .unit--openingHours {
  outline-color: #fff;
  min-height: 20px;
}
.unit--openingHours .part {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 1.25;
}
.unit--openingHours .part strong {
  text-transform: uppercase;
}
.unit--openingHours .part:first-child {
  margin-bottom: 20px;
}
.area--one {
  margin-top: calc(var(--spaceTotal) * 3);
  margin-bottom: calc(var(--spaceTotal) * 3);
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .area--one {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
body.layout1 .area--one.area6 {
  margin-top: calc(var(--spaceTotal) * 3);
  margin-bottom: calc(var(--spaceTotal) * 3);
}
body:not(.layout1) .area--one.area1 {
  margin-top: 0;
}
body:not(.layout1) .area--one.area1 .unit:first-child {
  margin-top: 0;
}
.area--two .unit {
  --spacePart: 0;
  --spaceUnit: 0;
  --spaceTotal: 0;
}
.area--two .unit .part.pict * {
  height: 100%;
}
.area--two .unit .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unit--fold {
  margin-top: 0;
  margin-bottom: 0;
}
.area--one .unit--fold + .unit--fold {
  margin-top: calc(var(--spacePart) * -1);
}
.area--one .unit--fold div.more {
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  box-sizing: border-box;
}
#edit .area--one .unit--fold div.more {
  height: auto;
}
.area--one .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
.area--one .unit--fold .fold-toggle {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--s-textcolor);
  padding-top: calc(var(--spacePart) * 1);
  color: var(--s-textcolor);
  padding-right: 70px;
  box-sizing: border-box;
  background-size: 20px 20px;
  background-position: 100% calc(50% + 5px);
  background-repeat: no-repeat;
  background-image: url(/images/icon-foldopen-color-black.svg);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.area--one .unit--fold .fold-toggle--open {
  background-image: url(/images/icon-foldclose-color-black.svg);
}
.area--one .unit--fold.unit--foldOpen div.more {
  height: calc(var(--js-elementHeight));
}
.area--one .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s 0.2s;
}
.area--one .unit--fold .unit__foot {
  display: none;
}
.area--one .unit--slider:first-child {
  margin-top: calc((var(--spaceTotal) * -1) + (var(--spacePart) * 2));
}
.area--one .unit--slider:last-child {
  margin-bottom: calc((var(--spaceTotal) * -1) + (var(--spacePart) * 2));
}
#view .cbdModule--moodTitle,
#view #head {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s ease-in-out;
}
#view .cbdModule--moodTitle.is-intersecting,
#view #head.is-intersecting {
  opacity: 1;
  transform: translateX(0);
}
#view .area--one .unit:not(.unit--isometrie) .unit__background,
#view .area--one .unit:not(.unit--isometrie) .part {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s ease-in-out;
}
#view .area--one .unit:not(.unit--isometrie) .unit__background,
#view .area--one .unit:not(.unit--isometrie) .part.pict {
  transition-delay: 0.1s;
}
#view .area--one .unit:not(.unit--isometrie).is-intersecting .unit__background,
#view .area--one .unit:not(.unit--isometrie).is-intersecting .part {
  opacity: 1;
  transform: translateX(0);
}
.area--one .unitOne--1-1 .part.pict {
  --spacePart: 20px;
}
.area--one .unit--form .part:first-child h2,
.area--one .unitTwo .part:first-child h2 {
  margin-bottom: calc(var(--spacePart) * 1);
}
.area--one .unitFour .unit__background {
  margin-top: calc(var(--spacePart) * 1);
  margin-bottom: calc(var(--spacePart) * 1);
}
.section .area .part:not(.part--widget) img,
.cb-expo img {
  filter: blur(10px);
  transition: filter 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section .area .part:not(.part--widget) img.lazyloaded,
.cb-expo img.lazyloaded {
  filter: blur(0px);
}
.area--one .part--link {
  margin-top: calc(var(--spacePart) * 3);
}
.area--one .part--link + .part--link,
.area--one .part--link + .part.load {
  margin-top: var(--spacePart);
}
.area--one .part--link.part--styleOne .open {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.area--one .part--link.part--styleOne .open:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.area--one .part--link.part--styleOne .open:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .area--one .part--link.part--styleOne .open:hover,
  .area--one .part--link.part--styleOne .open:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .area--one .part--link.part--styleOne .open:hover:before,
  .area--one .part--link.part--styleOne .open:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .area--one .part--link.part--styleOne .open:hover:after,
  .area--one .part--link.part--styleOne .open:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.area--one .part--link.part--styleTwo .open {
  padding-left: 35px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  appearance: none;
  color: var(--s-btn-secondary-color);
  font-weight: bold;
  position: relative;
}
.area--one .part--link.part--styleTwo .open:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-short-right-black.svg);
  background-color: var(--s-btn-secondary-color);
}
@media (hover: hover) and (pointer: fine) {
  .area--one .part--link.part--styleTwo .open:hover:before,
  .area--one .part--link.part--styleTwo .open:focus:before {
    mask-image: url(/images/icon-plus-black.svg);
  }
}
.part.pict.part--styleOne .cb-image-caption {
  margin-top: 10px;
  color: var(--s-textcolor);
}
.part.pict.part--styleTwo .cb-image-container {
  background-color: #000000;
}
.part.pict.part--styleTwo .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  opacity: 0.5;
}
.part.pict.part--styleTwo .cb-image-figure {
  position: relative;
}
.part.pict.part--styleTwo .cb-image-caption {
  position: absolute;
  color: var(--s-textcolor);
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  --s-textcolor: #FFFFFF;
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal !important;
}
@media (hover: hover) and (pointer: fine) {
  .part.pict:hover .cb-image-container img,
  .part.pict:focus .cb-image-container img {
    opacity: 1;
  }
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.togglenavigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2001;
  width: 40px;
  height: 22px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: #FFFFFF;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.cb-toggle-target-active .tline {
  background-color: #FFFFFF;
}
.tline--2,
.tline--3 {
  width: 20px;
  left: unset;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  width: 40px;
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  width: 40px;
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.naviWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #2F1657;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}
body.cb-toggle-target-active .naviWrapper {
  transform: translateY(0);
}
.navi-animation {
  float: left;
  width: 100%;
  padding: 140px 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}
body.cb-toggle-target-active .navi-animation {
  transition: all 1s cubic-bezier(0.87, 0, 0.13, 1);
  transform: translateY(0);
  opacity: 1;
}
.naviFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 100px 40px;
}
.container--vcard {
  float: left;
  display: flex;
  gap: 40px;
}
@media (max-width: 1023px) {
  .container--vcard {
    flex-direction: column;
  }
}
.vcard--nav {
  min-width: 290px;
  padding-top: calc(var(--spacePart) * 2);
  box-sizing: border-box;
  color: var(--s-textcolor);
  --s-textcolor: #FFFFFF;
  --s-linkcolor: #FFFFFF;
}
.mobilenavi {
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .mobilenavi {
    width: 100%;
  }
}
.mobilenavi__main {
  float: left;
  max-width: 400px;
}
@media (max-width: 1023px) {
  .mobilenavi__main {
    width: 100%;
  }
}
.mobilenavi__second {
  float: left;
}
@media (max-width: 1023px) {
  .mobilenavi__second {
    width: 100%;
  }
}
.mobilenavi div.navi {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  --s-linkcolor: #FFFFFF;
}
@media (max-width: 1023px) {
  .mobilenavi div.navi {
    align-items: center;
  }
}
.mobilenavi div.navi > .cb-toggle {
  display: none;
}
.mobilenavi div.navi > .item {
  float: left;
  position: relative;
  width: 100%;
}
.mobilenavi div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobilenavi div.navi > .item > .menu {
  width: 100%;
  display: block;
}
.mobilenavi div.navi > .item > .menu:hover,
.mobilenavi div.navi > .item > .menu:focus,
.mobilenavi div.navi > .item > .menu.path {
  color: #DFFE52;
}
.mobilenavi__main div.sub1 {
  box-sizing: border-box;
  padding-right: 40px;
  gap: calc(var(--spacePart) * 3);
}
.mobilenavi__main div.sub1 > .item > .menu {
  font-size: 26px;
  line-height: 1.15384615;
  font-weight: 800;
}
@media (max-width: 767px) {
  .mobilenavi__main div.sub1 > .item > .menu {
    font-size: 22px;
    line-height: 1.36363636;
  }
}
.mobilenavi__main div.sub2 > .item.init {
  margin-top: calc(var(--spacePart) * 3);
}
.mobilenavi__main div.sub2 > .item:not(.exit) {
  margin-bottom: 22px;
}
.mobilenavi__main div.sub2 > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
}
.mobilenavi__second div.navi {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 120px;
}
.mobilenavi__second div.navi > .item {
  margin-bottom: 30px;
}
.mobilenavi__second div.navi > .item.exit {
  margin-bottom: 0;
}
.mobilenavi__second div.navi > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
}
@media (max-width: 1023px) {
  .mobilenavi__second div.navi {
    margin-top: 80px;
  }
}
.mobilenavi div.sub1 {
  box-sizing: border-box;
}
.mobilenavi div.sub1 > .item {
  box-sizing: border-box;
  padding-right: 50px;
}
.mobilenavi div.sub1 > .item:not(.exit) {
  margin-bottom: calc(var(--spacePart) * 1);
}
.mobilenavi div.navi > .item:not(.cb-toggle-target-active) > div.navi {
  overflow: hidden;
  max-height: 0;
}
.mobilenavi div.navi > .item:not(.cb-toggle-target-active) > div.navi > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobilenavi div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobilenavi div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobilenavi .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  height: 30px;
  mask-size: 30px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-toggle-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
  background-color: #D6D6D6;
}
@media (max-width: 767px) {
  .mobilenavi .cb-toggle {
    height: 32px;
  }
}
.mobilenavi .cb-toggle.cb-toggle-active {
  mask-image: url(/images/icon-toggleclose-black.svg);
}
.ns-slider {
  float: left;
  width: 100%;
  position: relative;
  gap: calc(var(--spacePart) * 2);
}
.section--slider {
  z-index: 1111;
}
@media (hover: hover) and (pointer: fine) {
  .section--slider:hover .ns-sliderControl,
  .section--slider:focus .ns-sliderControl {
    opacity: 1;
  }
}
.ns-slider__stage {
  float: left;
  width: 100%;
  position: relative;
}
.unit--slider {
  width: 100vw !important;
  left: 50%;
  margin-left: -50vw !important;
}
.ns-slider__area {
  float: left;
  width: calc(80% + 40px);
  max-width: 1280px;
  display: flex;
  align-items: center;
  margin-left: -20px;
}
@media (max-width: 1023px) {
  .ns-slider__area {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}
.ns-slider__section {
  float: left;
  width: 100%;
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.ns-slider__cover {
  float: left;
  width: 100%;
}
.ns-slide {
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  flex-shrink: 0;
  transition: all 0.8s ease-in-out;
}
@media (max-width: 1023px) {
  .ns-slide {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(75% - 20px);
  }
}
.ns-slider__part {
  float: left;
  width: 100%;
}
.ns-slider__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1.69333333;
}
@supports not (aspect-ratio: 1270 /  750) {
  .ns-slider__part--image img:before {
    float: left;
    padding-top: 59.05511811%;
    content: '';
  }
  .ns-slider__part--image img:after {
    display: block;
    content: '';
    clear: both;
  }
}
@media (max-width: 1023px) {
  .ns-slider__part--image img {
    aspect-ratio: 1.61842105;
  }
  @supports not (aspect-ratio: 615 /  380) {
    .ns-slider__part--image img:before {
      float: left;
      padding-top: 61.78861789%;
      content: '';
    }
    .ns-slider__part--image img:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
@media (max-width: 767px) {
  .ns-slider__part--image img {
    aspect-ratio: 1.14937759;
  }
  @supports not (aspect-ratio: 277 /  241) {
    .ns-slider__part--image img:before {
      float: left;
      padding-top: 87.00361011%;
      content: '';
    }
    .ns-slider__part--image img:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
.ns-slider__controls {
  padding-right: clamp(var(--deskSpace), calc((100vw - 1280px) / 2), calc((100vw - 1280px) / 2));
  box-sizing: border-box;
  position: relative;
  float: right;
  z-index: 2;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: calc(var(--spacePart) * 2);
}
.ns-slider--gallerySlider .ns-sliderControl {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  mask-size: 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: var(--s-slider-control-color);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-slider--gallerySlider .ns-sliderControl--prev {
  left: 0;
  mask-image: url(/images/icon-arrow-short-left-black.svg);
}
.ns-slider--gallerySlider .ns-sliderControl--next {
  right: 0;
  mask-image: url(/images/icon-arrow-short-right-black.svg);
}
/*# sourceMappingURL=./screen-small.css.map */